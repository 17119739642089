

@font-face {
	font-family: 'audimat';
	src: url("./fonts/audimat.ttf") format("truetype");
}
@font-face {
	font-family: 'dustismo';
	font-weight: bold;
	src: url("./fonts/Dustismo_Roman.ttf") format("truetype");
}

@font-face {
	font-family: 'arrow';
	font-weight: bold;
	src: url("./fonts/arrow.ttf") format("truetype");
}

@font-face {
	font-family: 'lato';
	font-weight: bold;
	src: url("./fonts/Lato-Bold.ttf") format("truetype");
}

p {
	font-size: 20px;
}	

body {
	background-color: #141414;
	background-repeat: no-repeat;
	background-size: 100vw, 100vh;
	margin: 0;
	font-family: "lato";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	color: #eee;
	font-family: "audimat";
}


body, p, a {
	color: #eee;
	font-family: "lato";
}

.titleText {
	transition: 300ms;
	font-size: 20px;
}

.titleText:hover {
	transition: 300ms;
	text-shadow:0 0 3px #0ff, 0 0 4px #0ff;
}

.icurety-header {
	font-size: 32px;
	color: cyan;
	font-family: 'audimat';
}

.mainFont {
	font-family: 'audimat';
}

.blinking-class {
	animation: blinking 0.8s infinite;
}

@keyframes blinking {
	0% {
		opacity: 0;
	}
	49% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}